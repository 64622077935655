<template>
    <div class="search__wrapper">
        <div class="search__trigger"  @click.prevent="toggle">
            <slot name="trigger"></slot>
        </div>

        <div class="search__screen" v-show="showing">
            <div class="container">
                <div class="search__close" v-show="trigger" @click.prevent="toggle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"/>
                    </svg>
                </div>

                <slot>
                    <form class="search__form" action="/">
                        <input type="search" class="search__input" ref="search__input" placeholder="Search …" name="s"
                               title="Search for:">
                        <button class="search__submit filled primary" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                      clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </form>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            showing: !this.trigger,
            inputVisible: false
        };
    },

    props: {
        trigger: {
            type: Boolean,
            default: true
        }
    },

    watch: {
        showing(isShowing) {
            if (isShowing) {
                document.body.classList.add('modal-open');
                this.$nextTick(() => {
                    this.$refs.search__input.focus();
                });
            } else {
                document.body.classList.remove('modal-open');
            }
        }
    },

    mounted() {
        document.addEventListener('keyup', evt => {
            if (evt.key === 'Escape') {
                this.showing = false;
            }
        });
    },

    methods: {
        toggle() {
            this.showing = !this.showing;
        },
    }
}

</script>
