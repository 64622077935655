<template>
    <div class="accordion__item"
         :class="{
             'accordion__item--active': isOpen,
             'accordion__item--disabled': disabled
         }"
    >

        <component :is="headingTag"
                   :type="headingTag === 'button' ? 'button' : undefined"
                   :aria-expanded="isOpen ? 'true' : 'false'"
                   :aria-controls="id"
                   class="accordion__heading"
                   @click.prevent.stop="onClick"
        >

            <h5 class="accordion__title">
                <!-- @slot title for the accordion item -->
                <slot name="title">
                    <span v-if="title" v-html="title"></span>
                </slot>
            </h5>

            <div v-if="!noToggleIcon" class="accordion__icon">
                <!-- @slot icon for the accordion item -->
                <slot name="icon">
                    <!-- default icon -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16"
                         class="accordion__icon-default">
                        <path d="M12 9H7v5H5V9H0V7h5V2h2v5h5z"/>
                    </svg>
                </slot>
            </div>
        </component>

        <slide-vertical :active="isOpen" :id="id">
            <div class="accordion__body">
                <!-- @slot content for the accordion item -->
                <slot>
                    <p v-if="body" v-html="body"></p>
                </slot>
            </div>
        </slide-vertical>
    </div>
</template>

<script>
import {inject, ref, onMounted, onBeforeUnmount} from 'vue';
import useIdentifier from "@/composables/use-identifier";
import SlideVertical from "../transitions/SlideVertical";

export default {
    name: 'AccordionItem',
    components: {SlideVertical},
    props: {
        id: {
            type: [String, Number]
        },
        open: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        body: {
            type: String,
            required: true
        },
        headingTag: {
            type: String,
            default: 'div'
        },
        noToggleIcon: {
            type: Boolean,
            required: true
        },
    },
    setup(props) {
        // inject functions from accordion
        const addItem = inject('addItem');
        const removeItem = inject('removeItem');
        const onItemChange = inject('onItemChange');

        // add a unique identifier
        const id = useIdentifier();
        const isOpen = ref(props.open);

        // method for toggling accordion item
        const toggle = (forceState) => {
            const newState = forceState !== undefined ? forceState : !isOpen.value;

            // set the new open state
            isOpen.value = newState;

            // emit event for the item change
            onItemChange(id.value, newState);
        };

        // method for opening accordion item
        const toggleOpen = (silent = false) => {
            // set the new open state
            isOpen.value = true;

            // if not silent, emit event for the item change
            if (!silent) {
                onItemChange(id.value, true);
            }
        };

        // method for closing accordion item
        const toggleClose = (silent = false) => {
            // set the new closed state
            isOpen.value = false;

            // if not silent, emit event for the item change
            if (!silent) {
                onItemChange(id.value, false);
            }
        };

        // toggle the accordion item on click
        const onClick = () => {
            toggle(!isOpen.value);
        };

        // on mount, register the accordion item
        onMounted(() => {
            addItem(id.value, {open: isOpen.value, toggle, toggleOpen, toggleClose});
        });

        // remove the item before unmount
        onBeforeUnmount(() => {
            removeItem(id.value);
        });

        // pass the data/methods to the instance
        return {
            id,
            isOpen,
            onClick
        };
    }
};
</script>
