import ScrollLink from "@/components/navigation/ScrollLink";
import Accordion from "@/components/accordion/Accordion";
import Carousel from "@/components/carousel/Carousel";
import Cards from "@/components/cards/Cards";
import Card from "@/components/cards/Card";
import Lightbox from "@/components/lightbox/Lightbox";
import Search from "@/components/search/Search";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'fc-scroll-link': ScrollLink,
    'fc-accordion': Accordion,
    'fc-carousel': Carousel,
    'fc-cards': Cards,
    'fc-card': Card,
    'fc-lightbox': Lightbox,
    'fc-search': Search
};
