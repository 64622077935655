<template>
    <div class="glide__bullets" data-glide-el="controls[nav]">
        <span :class='"glide__bullet " + (this.$parent.currentSlide !== (n - 1) ? bulletColorClass : "") + (this.$parent.currentSlide === (n - 1) ? bulletActiveColorClass : "")'  v-for="n in count" :data-glide-dir="'='+(n - 1)" aria-label="carousel bullet navigation control"></span>
    </div>
</template>

<script>
export default {
    props: {
        count: {
            type: Number
        },
        bulletActiveColorClass: {
            type:    String,
            default: ''
        },
        bulletColorClass: {
            type:    String,
            default: ''
        }
    },
    mounted() {
    },
}
</script>
this.$parent.currentSlide
