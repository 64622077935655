<template>
    <component :is="tag" class="accordion">
        <slot>
            <accordion-item
                v-for="(item, index) in items"
                :title="item[titleName]"
                :body="item[bodyName]"
                :no-toggle-icon="noToggleIcon"
                :key="index"
            ></accordion-item>
        </slot>
    </component>
</template>

<script>
import {reactive, computed, provide} from 'vue';
import AccordionItem from "./AccordionItem";

export default {
    name: 'Accordion',
    components: {AccordionItem},
    props: {
        tag: {
            type: String,
            default: 'div'
        },

        items: {
            default: {}
        },

        titleName: {
            type: String,
            default: 'title'
        },

        bodyName: {
            type: String,
            default: 'body'
        },

        singleOpen: {
            type: Boolean,
            default: false
        },

        noToggleIcon: {
            type: Boolean,
            default: false
        },
    },

    setup(props, {emit}) {
        // create an empty map for storing accordion items
        const items = reactive(new Map());

        // computed prop for managing state
        const state = computed({
            get() {
                // map the items
                return Array.from(items).map(item => ({
                    id: item[0], // i.e. key
                    open: item[1].open, // i.e. value
                }));
            },
            set(currentStates) {
                // for each item, make sure its current 'open' state matches
                currentStates.forEach(currentState => {
                    const item = items.get(currentState.id);
                    if (item?.open !== currentState.open) {
                        item.toggle();
                    }
                });
            },
        });

        const emitChange = (itemId, open) => {
            emit('change', {
                change: {id: itemId, open},
                state: state.value,
            });
        };

        // provide needed functions to accordion items
        provide('addItem', (itemId, item) => {
            items.set(itemId, item);
        });
        provide('removeItem', itemId => {
            items.delete(itemId);
        });
        provide('onItemChange', (clickedItemId, open) => {
            items.get(clickedItemId).open = open;

            emitChange(clickedItemId, open);

            // check if only allow single item open
            if (props.singleOpen) {
                items.forEach((item, itemId) => {
                    // if not the clicked item and if open, close the item
                    if (itemId !== clickedItemId && item.open) {
                        item.toggleClose(true);
                        emitChange(itemId, false);
                    }
                });
            }
        });

        return {
            state
        };
    },
    mounted() {
        let vueInstanceWrapper = this.$el.parentElement;
        vueInstanceWrapper.classList.remove('visually-hidden');
    },
}
</script>
