document.addEventListener('DOMContentLoaded', function () {
    if(window.location.pathname.indexOf("styleguide") > -1 ) {
        console.log('Welcome to the StyleGuide');
        /**
         * Table of Contents
         */
        const triggers = document.getElementsByClassName('component-section-trigger');
        const components = document.getElementsByClassName('component');
        const componentsWrapper = document.getElementById('components-wrapper');

        function toggleComponent(i) {
            // Remove active state from all table-of-contents items
            for (let j = 0; triggers.length > j; j++) {
                triggers[j].classList.remove('active');
            }

            // Hide all components
            for (let l = 0; components.length > l; l++) {
                components[l].style.opacity = 0;
                components[l].style.zIndex = 0;
            }

            // Display the target component
            let targetId = i;
            if (typeof (i) !== 'string') {
                targetId = triggers[i].getAttribute('data-target-id');
            }

            let component = document.getElementById(targetId);
            let componentHeight = component.clientHeight;

            componentsWrapper.style.height = componentHeight + 'px';
            component.style.opacity = 1;
            component.style.zIndex = 1;

            // Set the table-of-contents item to active state
            if (typeof (i) !== 'string') {
                triggers[i].classList.add('active');
            } else {
                document.getElementById(i).classList.add('active');
            }

            // append query param for current component
            queryParams.set('component', targetId);
            let newRelativePathQuery = window.location.pathname + '?' + queryParams.toString();
            history.pushState(null, '', newRelativePathQuery);
        }

        // Determine if we should load component on page load based on query param
        const queryParams = new URLSearchParams(window.location.search);
        const requestedComponent = queryParams.get('component');
        if (requestedComponent) {
            toggleComponent(requestedComponent);
        } else {
            toggleComponent('colors');
        }

        // Apply UI actions for each component
        for (let i = 0; triggers.length > i; i++) {
            // Add click event to each table-of-contents item and apply logic
            triggers[i].addEventListener("click", function () {
                toggleComponent(i);
            });
        }


        /**
         * Spacing Section
         */
        const spacingSection = document.getElementById('spacing');
        const body = document.getElementsByTagName('body')[0];
        const bodyFontSizeEl = document.getElementById('body-font-size');
        const bodyLineHeightEl = document.getElementById('body-line-height');
        const pixelValues = document.getElementsByClassName('pixel-value');

        function calculateSpacingStats() {
            let bodyFontSize = window.getComputedStyle(body, null).getPropertyValue('font-size');
            let bodyLineHeight = window.getComputedStyle(body, null).getPropertyValue('line-height');

            bodyFontSizeEl.innerHTML = parseFloat(bodyFontSize).toFixed(2) + 'px';
            bodyLineHeightEl.innerHTML = (parseFloat(bodyLineHeight) / parseFloat(bodyFontSize)).toFixed(1);

            for (let i = 0; pixelValues.length > i; i++) {
                let unitModifier = pixelValues[i].getAttribute('data-modifier');
                let pixelValue = parseFloat(unitModifier) * parseFloat(bodyLineHeight);
                pixelValues[i].innerHTML = pixelValue.toFixed(2) + 'px';
            }
        }

        if (spacingSection) {
            window.addEventListener("resize", calculateSpacingStats);
            calculateSpacingStats();
        }
    }
});
